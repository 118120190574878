
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './login/service/auth.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'kakoo';

  constructor(private loginService: AuthService, private swUpdate: SwUpdate) {}

  ngOnInit(): void {
    this.loginService.setupBeforeInstallPromptListener();

    if (window.location.href.includes('utm_source=facebook')) {
      fbq('track', 'PageView', { source: 'facebook_ads' });
    }

    // if (this.swUpdate.isEnabled) {
    //   this.swUpdate.versionUpdates.subscribe(() => {
    //     if (confirm('New version available! Update the page?')) {
    //       window.location.reload();
    //     }
    //   });
    // }
  }
}
